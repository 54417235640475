import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Key Facts `}</strong></p>
        <ul>
          <li parentName="ul">{`momentum strategy`}</li>
          <li parentName="ul">{`invests in ETFs tracking stocks and bonds`}</li>
          <li parentName="ul">{`rebalances monthly w/ possible daily exit`}</li>
        </ul>
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-mach-1/"
            }}>{`Mach-1`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-lethargic-asset-allocation"
            }}>{`Lethargic Asset Allocation`}</a></li>
        </ul>
      </Aside>
      <Border mdxType="Border">
        <p>{`This strategy is a `}<strong parentName="p">{`proof of concept`}</strong>{` and not meant for investing. If you are looking for a simple portfolio, we suggest using `}<a parentName="p" {...{
            "href": "/portfolios/tt-easy-peasy/"
          }}>{`Easy Peasy`}</a>{`.`}</p>
      </Border><br />
      <p><em parentName="p">{`Market Vane`}</em>{` is a proprietary sentiment indicator for the U.S. stock market, which we first introduced in January 2021. `}<em parentName="p">{`Market Vane`}</em>{` can be used for a wide array of investment applications. The `}<em parentName="p">{`Market Vane`}</em>{` strategy denotes the simplest among them: a strategy switching between an ETF tracking the S&P 500 and another tracking 10-Year U.S. Treasuries.`}</p>
      <p><em parentName="p">{`Market Vane`}</em>{` technically might change its asset allocation any day. However, it changes its asset allocation only infrequently, often holding positions for many months. Consequently, the strategy's maintenance requirements are remarkably low.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Market Vane: A simple strategy",
                "title": "TuringTrader.com, Market Vane: A simple strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/market-vane/"
            }}>{`Market Vane Indicator`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of the `}<em parentName="p">{`Market Vane`}</em>{` strategy can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`hold S&P 500 while the `}<em parentName="li">{`Market Vane`}</em>{` indicator is positive`}</li>
        <li parentName="ul">{`hold 10-Year U.S. Treasuries otherwise`}</li>
      </ul>
      <p>{`For a detailed discussion of the inner workings, investors are encouraged to check the `}<a parentName="p" {...{
          "href": "/dashboard/market-vane/"
        }}><em parentName="a">{`Market Vane`}</em>{` indicator`}</a>{` page and read our `}<a parentName="p" {...{
          "href": "/articles/market-vane/"
        }}>{`background article`}</a>{`.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Market Vane`}</em>{` holds only a single ETF at a time, representing either the S&P 500, or 10-Year U.S. Treasuries. Because `}<em parentName="p">{`Market Vane`}</em>{` can exit the stock market any day in case volatility spikes or the index suffers severe drawdowns, the strategy deals very well with any risks evolving over several days. Further, because U.S. Treasuries are among the most trustworthy investments available, and `}<em parentName="p">{`Market Vane`}</em>{` only uses intermediate maturities, it is also relatively stable in environments of rising yields.`}</p>
      <p>{`The Monte-Carlo simulation of historical returns shows that `}<em parentName="p">{`Market Vane`}</em>{`'s form of `}<em parentName="p">{`serial diversification`}</em>{` greatly reduces risk, while, in the long term, offering a solid upside over the S&P 500.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`During bullish periods, `}<em parentName="p">{`Market Vane`}</em>{` keeps up with the S&P 500. However, when the stock market turns sour, `}<em parentName="p">{`Market Vane`}</em>{` quickly exits risky assets and flees into the safety of U.S. Treasuries. As a result, the strategy gains over its benchmark during pronounced downturns, with only brief periods of underperformance.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`While `}<em parentName="p">{`Market Vane`}</em>{` trades only infrequently, it might hold assets for less than 12 months, leading to short-term capital gains. Consequently, the strategy works best in tax-deferred acccounts.`}</p>
      <p>{`Because `}<em parentName="p">{`Market Vane`}</em>{` holds only a single ETF at a time, a minimum investment of $1,000 is all it needs to get started.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, January 2021`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      